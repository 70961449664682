import React from 'react';
import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const styles = (theme) => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
});

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h5',
      },
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
    h3: { component: Typography, props: { gutterBottom: true, variant: 'subtitle1' } },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'caption', paragraph: true },
    },
    p: { component: Typography, props: { paragraph: true } },
    a: { component: Link },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      )),
    },
  },
};

const post1 = `
## Being Grounded: The Physiological Prerequisite for the Good Life

Do you feel comfortable in your body, able to walk easily, free of neck tension and back pain? 
Do you feel stable and also enjoy a suppleness and ease of motion? Do you breathe easily and are able to relax?

These are signs of a harmonious experience of your body. It is the physical state that people poetically refer to as “being grounded.” Being grounded is associated with a cascade of biological processes within the body—both large and small—that foster health, growth, and restoration. 

#### It is the physiological prerequisite for an enjoyable life where you can feel good, be social, and learn new skills and ideas.

Imagine if you were in a situation where you didn’t feel grounded, say walking with a friend in an unsafe part of town. Would you be attentive to your friend, or on the lookout for footsteps coming up behind you? Would this be a good time for you to learn something new? Clearly not. When you are ungrounded, you feel unsafe and your body is in a state of hyper-vigilance—putting your health and higher functions on hold. Or think of a time when you’ve had gastrointestinal distress. How well were your cognitive abilities then?

#### If you are feeling ungrounded, part of you—however subtly—is still in a state of defense, monitoring your environment for safety and survival.

Your body is nobly engaging an adaptive mechanism on your behalf, but this is not the physiological basis for growth, creativity, and expansiveness. It is only when your nervous system registers you are safe that you become calm enough to make new circuits, new beginnings, and experience new possibilities. Harmony and comfort—“being grounded”—are requirements of the soul, and non-negotiable for personal happiness.
`;

const post2 = `
## Sessions

#### There is no health without grounding.

While it would be nice to think that your neo-cortex could simply command yourself to relax and de-stress, research has shown that your system does not respond well to such an approach. The part of you that needs to relax, and controls your ability to do so, simply does not take orders. However, it does respond to a succession of gentle invitations to unfurl, to let go of the tensions that have amassed from past slights and frustrations. As such, working directly with the body can be a gentle and effective way to change your physiology and positively impact your health.

In a 90-minute session, the client lies down and gentle movements are administered. Props are used to support the body and provide sensorimotor feedback to bring the parts of you that have been off-line back into your awareness. The gentleness of the technique is key, because without a feeling of safety, your body—courtesy of your reptilian brain which oversees your bodily coordination—hangs onto its old patterns and simply builds on top of them: It continues to bypass the parts that are off-line and in compensation, layers tension onto the parts it knows are on-line. When you are lying down with the aid of soft props, you are undergoing a totally different experience: You feel as if you are floating on a raft, which people report as very pleasant, calming, and restorative. Your body has no need to be on the defensive or to employ habits of tension, and it is in a much better position to support growth and new connections. In this environment, you are guided through gentle movements to help you find and reincorporate forgotten parts of yourself. It is a deeply relaxing experience—leaving you feeling much more grounded, spacious, and integrated.

#### Feeling “more integrated” can be a difficult to describe in words before you have had the experience, but it is akin to the difference between wrangling a wobbly grocery cart versus handling one that moves along with relative ease.

In this case, you—and the body in which you travel throughout your day—are the metaphorical grocery cart. As a result of your session, you’ll find that your breathing is easier, your thinking is clearer, your posture improved, your aches and pains diminish. The amount of “work” you need to do to accomplish things decreases since there is less internal resistance. There is a marked lightness and ease to your movement. 

The session is customized to you as an individual and thus focuses on your specific concerns. If you would like homework to help support these changes, time at the end of the session will be allotted to show you individualized exercises or stretches that will specifically benefit you.
`;

const post3 = `
## About the Technique

#### Sylvia is highly observant and has a gift for spotting tension patterns and alignment issues.

Her technique draws on years of experience working with students and clients. She finds that people experience the greatest benefits to their health when they can relax into their changes, because there is no aspect of health that solely exists on a physical terrain; people inhabit a psychological terrain as well. De-stressing is a vital component of her technique because few people simply want the cessation of pain. They want—and deserve—to experience themselves in a way that is deeply comfortable, one that fosters an increased capacity for both growth and delight. 

#### Synthesizing her knowledge in movement modalities with her studies in trauma release work and neuroscience, Sylvia has developed an integrative mind-body approach that ultimately focuses on harmony and comfort.

Building on the Feldenkrais Method®, the Franklin Method®, and Restorative Exercise™—all of which aim to restore the body to health by teaching it move in concert with the way it was designed—her technique addresses the engineering of the body while honoring the unique sensitivities of individual clients.
`;

export default function MarkdownPosts() {
  return (
    <div>
        <ReactMarkdown options={options}>{post1}</ReactMarkdown>
        <ReactMarkdown options={options}>{post2}</ReactMarkdown>
        <ReactMarkdown options={options}>{post3}</ReactMarkdown>
    </div>
  );
  
}
